/**
 * Base service class for handling common functionalities related to in-app purchases.
 */
export class BaseService {
  /**
   * Constructs the BaseService instance.
   *
   * @param {Object} iapManager - The in-app purchase manager instance.
   */
  constructor(iapManager) {
    this.iapManager = iapManager;
    this.a2u = iapManager.device.a2u;
  }

  /**
   * Logs an error using the a2u error logger.
   *
   * @param {Error} error - The error to be logged.
   */
  logError(error) {
    try {
      this.a2u.errorLogger.log(error);
    } catch (e) {
      console.error('Failed to log error', e);
    }
  }
}
