import {loggerDrivers} from './logger-drivers';

/**
 * ErrorLogger class handles error logging using various drivers.
 */
export class ErrorLogger {
  /**
   * Singleton instance of ErrorLogger.
   * @type {ErrorLogger|null}
   */
  static instance = null;

  /**
   * Constructs a new instance of ErrorLogger.
   *
   * @param {Object} a2u - The A2U instance used for logging.
   */
  constructor(a2u) {
    this.a2u = a2u;
    this.drivers = {};
  }

  /**
   * Returns the singleton instance of ErrorLogger, creating it if necessary.
   *
   * @param {Object} device - The device object containing the A2U instance.
   * @returns {ErrorLogger} The singleton instance of ErrorLogger.
   */
  static getInstance(device) {
    if (!this.instance) {
      this.instance = new ErrorLogger(device.a2u);

      this.instance.init();
    }
    return this.instance;
  }

  /**
   * Initializes the ErrorLogger by creating instances of the available logger drivers.
   */
  init() {
    if (!Object.keys(loggerDrivers).length) {
      return;
    }

    Object.entries(loggerDrivers).forEach(([name, driver]) => {
      this.drivers[name] = new driver(this);
    });
  }

  /**
   * Logs an error using all available logger drivers.
   *
   * @param {Error|string} error - The error object or message to be logged.
   * @returns {Promise<void>} A promise that resolves when the error has been logged by all drivers.
   */
  async log(error) {
    if (!Object.keys(this.drivers).length) {
      return;
    }

    for (const driver of Object.values(this.drivers)) {
      try {
        await driver.processError(error);
      } catch (e) {
        console.error('ErrorLogger.logError', e);
      }
    }
  }
}
