// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from '../../../secrets/firebaseConfig';

export class FirebaseAnalytics {

    // Singleton instance
    static instance = undefined;

    /**
     * Init analytics
     */
    init(config) {
        try {
            this.app = initializeApp(config);
            this.analytics = getAnalytics(this.app);
            return this
        } catch (e) {
            console.error(e)
            return null
        }
    }


    /**
     * Constructor
     */
    static getInstance() {
        if (process.env.VUE_APP_RUN_MODE !== 'release') {
            return undefined;
        }

        // Create new instance
        if(FirebaseAnalytics.instance === undefined) {
            FirebaseAnalytics.instance = firebaseConfig ? new FirebaseAnalytics().init(firebaseConfig) : false
        }

        // Return instance
        return FirebaseAnalytics.instance;
    }

    /**
     * Set user property
     * @param key
     * @param value
     * @return {Promise<void>}
     */

    setUserProperty(key, value) {
        console.log('setUserProperty', key, value, ' - not implemented')
    }


    /**
     * Log event
     * @param name
     * @param Object
     */
    logEvent({name, params}) {
        logEvent(this.analytics, name, params);
    }
}
