import {BaseDriver} from './BaseDriver';

/**
 * ConsoleLogDriver class extends BaseDriver to handle error processing by logging errors to the console.
 */
export class ConsoleLogDriver extends BaseDriver {
  /**
   * Processes an error by logging it to the console.
   *
   * @param {Error|string} err - The error object or message to be processed.
   * @returns {Promise<void>} A promise that resolves when the error has been processed.
   */
  async processError(err) {
    // Logs the error to the console
    console.error(err);
  }
}
