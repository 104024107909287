import {ATUFunctionPackage} from "a2u-renderer-common/src/utils/processor/ATUFunctionPackage";

export class IapPackage extends ATUFunctionPackage {
  /**
   * Checks if a specific product has been purchased.
   *
   * @param {Object} param - The parameter object.
   * @param {string} param.productId - The ID of the product to check.
   * @returns {boolean} True if the product has been purchased, otherwise false.
   */
  checkIfProductPurchased({productId}) {
    try {
      const iapManager = this.parent?.renderer?.a2u?.getDevice()?.getPluginInstance?.("IAPManager");

      if (!iapManager) {
        return false;
      }

      return iapManager.checkIfPurchasedProduct(productId) === true;
    } catch (e) {
      console.error(`Error check purchase. Product ID: ${productId}`, e);

      return false;
    }
  }

  /**
   * Checks if any product in a specific group has been purchased.
   *
   * @param {Object} param - The parameter object.
   * @param {string} param.groupId - The ID of the group to check.
   * @returns {boolean} True if any product in the group has been purchased, otherwise false.
   */
  checkIfGroupPurchased({groupId}) {
    try {
      const iapManager = this.parent?.renderer?.a2u?.getDevice()?.getPluginInstance?.("IAPManager");

      if (!iapManager) {
        return false;
      }

      return iapManager.checkIfPurchasedGroup(groupId) === true;
    } catch (e) {
      console.error(`Error check purchase. Group ID: ${groupId}`, e);

      return false;
    }
  }
}
