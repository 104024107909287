import {BaseAdapter} from './BaseAdapter.js';

/**
 * FirebaseAdapter class extends the BaseAdapter class to provide
 * functionality for emitting events to Firebase.
 */
export class FirebaseAdapter extends BaseAdapter {
  /**
   * Emits an event to Firebase.
   *
   * @param {string} event - The name of the event to emit.
   * @param {Object} [payload=null] - The payload data to send with the event.
   */
  emit(event, payload = null) {
    try {
      // Check if the firebase logEvent function is available
      if (typeof this.config?.firebase?.logEvent !== 'function') {
        return;
      }

      // Emit the event to Firebase with the provided name and payload
      this.config.firebase.logEvent({
        name: event,
        params: payload || {},
      });
    } catch (e) {
      // Log an error if the event emission fails
      console.error('Firebase emit error', e);
    }
  }
}
