/**
 * BaseAdapter class for marketing pixel integration.
 * This class serves as a base for different marketing pixel adapters by providing a common structure and interface.
 * It should be extended by specific adapter implementations to integrate various marketing pixel services.
 *
 * @class
 * @param {Object} config - Configuration object specific to the marketing pixel service.
 */
export class BaseAdapter {
  /**
   * Constructor for the BaseAdapter class.
   * Initializes the adapter with the provided configuration.
   *
   * @param {Object} config - Configuration object for the adapter.
   */
  constructor(config) {
    this.config = config;
  }

  /**
   * Initialization method for the adapter.
   * This method should be overridden by subclasses to perform any necessary initialization steps,
   * such as setting up connections or preparing the environment for the marketing pixel service.
   * The base implementation simply logs a message indicating that the method is not implemented.
   */
  init() {
    console.log('Method "init" is not implemented');
  }

  /**
   * Emits an event to the marketing pixel service.
   * This method should be overridden by subclasses to actually send events to the marketing pixel service.
   * The base implementation logs the event and payload, followed by a message indicating that the method is not implemented.
   *
   * @param {string} event - The name of the event to emit.
   * @param {Object|null} payload - The payload of the event, if any.
   */
  emit(event, payload = null) {
    console.log(event, payload);
    console.log('Method "emit" is not implemented');
  }
}
