/**
 * Base class for platform-specific adapters.
 */
export class BasePlatformAdapter {
  /**
   * A map to store event listeners.
   * @type {Map<string, Set<Function>>}
   */
  static eventListeners = new Map();

  /**
   * Adds a listener for a specific event.
   *
   * @param {string} event - The name of the event.
   * @param {Function} listener - The listener function to be called when the event is emitted.
   */
  static addListener(event, listener) {
    if (!this.eventListeners.has(event)) {
      this.eventListeners.set(event, new Set());
    }

    this.eventListeners.get(event).add(listener);
  }

  /**
   * Removes a listener for a specific event.
   *
   * @param {string} event - The name of the event.
   * @param {Function} listener - The listener function to be removed.
   */
  static removeListener(event, listener) {
    if (this.eventListeners.has(event)) {
      this.eventListeners.get(event).delete(listener);
    }
  }

  /**
   * Emits an event, calling all listeners associated with the event.
   *
   * @param {string} event - The name of the event.
   * @param {...*} data - The data to be passed to the listener functions.
   */
  static emitEvent(event, ...data) {
    if (this.eventListeners.has(event)) {
      for (const listener of this.eventListeners.get(event)) {
        listener(...data);
      }
    }
  }
}
