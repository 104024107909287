import {marketingPixelEventsList as pixelEvents} from 'a2u-renderer-common/src/constants/marketingPixelEvents.js';
import {FacebookAdapter} from './adapters/FacebookAdapter.js';
import {TikTokAdapter} from './adapters/TikTokAdapter.js';
import {FirebaseAdapter} from './adapters/FirebaseAdapter.js';

/**
 * MarketingPixelsPlugin class to manage marketing pixel integrations.
 * This class handles the initialization of different marketing pixel adapters (e.g., Facebook, TikTok)
 * and the emission of events to these platforms based on the configured integrations and event mappings.
 */
export class MarketingPixelsPlugin {
  // Object to hold instances of adapters by their names.
  adapters = {}

  // Map to associate marketing events with platforms.
  eventsMap = new Map;

  /**
   * Retrieves the singleton instance of the MarketingPixelsPlugin class.
   * @returns {MarketingPixelsPlugin} The singleton instance.
   */
  static getInstance() {
    if(!this.instance) {
      this.instance = new MarketingPixelsPlugin();
    }

    return this.instance;
  }

  /**
   * Initializes the plugin with the provided integrations.
   * It sets up adapters for each integration (e.g., Facebook, TikTok) and initializes them.
   * Also, it populates the eventsMap with events and their corresponding platforms from a predefined list.
   * @param {Object} integration - The integrations object containing platform IDs.
   */
  init(integration) {
    if (integration?.facebookId) {
      this.adapters.facebook = new FacebookAdapter({
        pixelId: integration.facebookId,
      });
    }

    if (integration?.tiktokId) {
      this.adapters.tiktok = new TikTokAdapter({
        pixelId: integration.tiktokId,
      });
    }

    if (integration.firebase) {
      this.adapters.firebase = new FirebaseAdapter({
        firebase: integration.firebase,
      });
    }

    for (const adapter of Object.values(this.adapters)) {
      adapter.init();
    }

    for (const item of pixelEvents) {
      this.eventsMap.set(item.value, item.platforms);
    }
  }

  /**
   * Emits an event to the appropriate marketing pixel platforms.
   * It checks for the existence of adapters and the event in the eventsMap.
   * If found, it emits the event to the corresponding platform adapters.
   * @param {string} event - The name of the event to emit.
   * @param {Object|null} payload - The payload of the event, if any.
   */
  emit(event, payload = null) {
    if (!Object.keys(this.adapters).length) {
      console.error('No adapters found');
      return;
    }

    const eventMeta = this.eventsMap.get(event);

    if (!eventMeta) {
      console.error('Event not found');
      return;
    }

    for (const [platform, adapter] of Object.entries(this.adapters)) {
      const platformEvent = eventMeta[platform];

      if (!platformEvent) {
        console.error('Event not found for platform');
        continue;
      }

      adapter.emit(platformEvent, payload);
    }
  }
}
