<template>
  <q-dialog class="stripe-dialog z-max" full-width position="bottom">
    <q-card>
      <div class="row justify-end">
        <q-btn flat round icon="close" v-close-popup />
      </div>

      <q-card-section>
        <div ref="stripeCheckout" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'StripeDialog',

  props: ['apiKey', 'clientSecret', 'onComplete'],

  data() {
    return {
      checkout: null,
    };
  },

  async created() {
    // Dynamically import Stripe.js
    const { loadStripe } = await import('@stripe/stripe-js');

    // Load Stripe.js
    const stripe = await loadStripe(this.apiKey);

    // Initialize Stripe Checkout
    this.checkout = await stripe.initEmbeddedCheckout({
      fetchClientSecret: async () => this.clientSecret,
      onComplete: this.onComplete || (() => {}),
    });

    // Mount the checkout form
    this.checkout.mount(this.$refs.stripeCheckout);
  },

  beforeUnmount() {
    if (!this.checkout) {
      return;
    }

    // Destroy the checkout form
    this.checkout.destroy();
  }
}
</script>

<style lang="scss">
  body.q-ios-padding .stripe-dialog {
    .q-dialog__inner {
      padding-top: 21px !important;

      & > div {
        max-height: calc(var(--screen-height, 100vh) - 21px) !important;
      }
    }
  }
</style>
