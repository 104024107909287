import {BaseService} from './BaseService';
import {MarketingAnalytics} from './MarketingAnalytics';

/**
 * Service for handling post-purchase operations for in-app purchases.
 */
export class IapPostPurchase extends BaseService {
  /**
   * Handles the post-purchase process.
   *
   * @param {string} productId - The ID of the purchased product.
   * @param {Object} res - The response object containing purchase details.
   * @param {number} res.price - The price of the purchased product.
   * @param {string} res.currency - The currency of the purchased product.
   * @returns {Promise<void>}
   */
  async postPurchase(productId, res) {
    try {
      if (this.iapManager.targetPlatform !== 'web') {
        return;
      }

      const analyticsService = new MarketingAnalytics(this.iapManager);

      await analyticsService.emitPurchaseEvent(res?.price, res?.currency);
    } catch (e) {
      console.error('Error in postPurchaseMobile:', e);

      // Log error
      this.logError(e);
    }
  }
}
