import {BaseService} from './BaseService';

/**
 * Service for handling marketing analytics related to in-app purchases.
 */
export class MarketingAnalytics extends BaseService {
  /**
   * Emits a purchase event to the marketing analytics plugin.
   *
   * @param {number} price - The price of the purchased product.
   * @param {string} currency - The currency of the purchased product.
   * @returns {Promise<void>}
   */
  async emitPurchaseEvent(price, currency) {
    try {
      if (this.a2u?.runMode !== "release") {
        return;
      }

      const plugin = this.a2u.getDevice()?.getPluginInstance?.("MarketingPixelsPlugin");

      if(!plugin) {
        return;
      }

      plugin.emit('Purchase', {
        value: price,
        currency,
      });
    } catch (e) {
      console.error('Error emitting marketing event:', e);

      // Log error
      this.logError(e);
    }
  }
}
