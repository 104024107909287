import {BaseAdapter} from './BaseAdapter.js';

/**
 * FacebookAdapter extends the BaseAdapter to implement Facebook Pixel integration.
 * This adapter initializes the Facebook Pixel script and provides a method to emit events to Facebook Pixel.
 */
export class FacebookAdapter extends BaseAdapter {
  /**
   * Initializes the Facebook Pixel script asynchronously.
   * It injects the Facebook Pixel script into the document if it's not already present.
   * Upon successful injection, it initializes the pixel with the provided pixel ID from the adapter's configuration.
   * If an error occurs during the initialization process, it logs the error to the console.
   */
  init() {
    try {
      (function(f,b,e,v,n,t,s) {
        if(f.fbq)return; n=f.fbq=function(){n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0'; n.queue=[]; t=b.createElement(e); t.async=!0;
        t.src=v; s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq('init', this.config.pixelId);
    } catch (e) {
      console.error('Facebook pixel init error', e)
    }
  }

  /**
   * Emits an event to Facebook Pixel.
   * This method checks if the Facebook Pixel script is initialized and if not, attempts to initialize it.
   * It then emits the specified event along with an optional payload to Facebook Pixel.
   * If an error occurs during the event emission, it logs the error to the console.
   *
   * @param {string} event - The name of the event to emit.
   * @param {Object|null} payload - The payload of the event, if any.
   */
  emit(event, payload = null) {
    try {
      if (typeof window.fbq !== 'function') {
        this.init();
      }

      window.fbq(...['track', event, payload].filter(Boolean));
    } catch (e) {
      console.error('Facebook pixel emit error', e);
    }
  }
}
