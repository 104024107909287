import {BaseDriver} from './BaseDriver';

/**
 * A2uDriver class extends BaseDriver to handle error processing and logging using the A2U debug logger.
 */
export class A2uDriver extends BaseDriver {
  /**
   * Processes an error by logging it using the A2U debug logger if available.
   *
   * @param {Error|string} err - The error object or message to be processed.
   * @returns {Promise<void>} A promise that resolves when the error has been processed.
   */
  async processError(err) {
    // Retrieves the debugLogger from the componentInstance's renderer's a2u object
    const debugLogger = this.logger?.a2u?.debugLogger || undefined;

    // If debugLogger is not available, the function returns
    if (!debugLogger) {
      return;
    }

    try {
      // If debugLogger is available, it logs an 'unhandled' event with the error message and additional data
      debugLogger.log({
        type: 'unhandled',
        message: err?.message || err,
        data: {},
      });
    } catch (e) {
      console.error('A2uErrorHandler.processError', e);
    }
  }
}
