import {createApp} from 'vue'
import App from './App.vue'
import AppToUpRenderer from "app-to-up-vue-renderer/src/install.js"
import AbApp from "ab-application/src/install"
import {getConfig} from "./config";
import errorHandlerPlugin from './error-handler-plugin';
import "./styles/fonts.css"
import "./styles/styles.css"
import "./styles/pwa-elements.css"

// Create app
const app = createApp(App);

// Config
app.config.unwrapInjectedRef = true

// Init error handler plugin
app.use(errorHandlerPlugin);

// Include app to up renderer
app.use(AppToUpRenderer)

// Init app app, backendUrl, socketUrl, routes, translations, stateConfig, dbConfig
app.use(AbApp, {
        //baseURL: process.env.VUE_APP_BACKEND_URL,
        //authURL: process.env.VUE_APP_DB_URL,
        requestTimeout: 5*60*1000,
        config: async () => {
            return await getConfig(process.env.NODE_ENV !== 'production' ? `app-settings-${process.env.VUE_APP_MODULE_ID}` : 'app-settings')
        },
        db: false,
        translations: { // initialize i18n instance
          language: 'en',
          resources: {}
        }
    },
    false, () => {

        // Mount app
        app.mount('#app');
    })
