/**
 * BaseDriver class serves as a base class for error handling drivers.
 */
export class BaseDriver {
  /**
   * Constructs a new instance of BaseDriver.
   *
   * @param {Object} logger - The logger instance to be used for logging errors.
   */
  constructor(logger) {
    this.logger = logger;
  }

  /**
   * Processes an error by logging it to the console.
   *
   * @param {Error|string} err - The error object or message to be processed.
   * @returns {Promise<void>} A promise that resolves when the error has been processed.
   */
  async processError(err) {
    console.error(err);
  }
}
